import PropTypes from "prop-types";
import React from "react";
import Container from "react-bootstrap/Container";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

const Controller = ({ siteTitle }) => {
    return (
        <Layout>
            <SEO />
            <section id={"produk"} className={"section-bg"}>
                <Container>
                    <div className={"section-title"}>
                        <h1>Indoor LED Display Module</h1>
                    </div>
                </Container>
            </section>
        </Layout>
    );
};

Controller.propTypes = {
    siteTitle: PropTypes.string,
};

Controller.defaultProps = {
    siteTitle: ``,
};


export default Controller;
